<template>
    <div class="account_profile">
        <UserLoginVue v-if="!isUserLogged" contentKey="authButtons"></UserLoginVue>
        <form data-abide novalidate data-validate-on-blur="true" v-show="isUserLogged">
            <!-- STEP 1 -->
            <div class="grid-x grid-margin-x align-justify block-section">
                <div class="cell large-6">
                    <label for="firstName">
                        <input type="text" v-model="dataUser.user.firstName" @blur="validateName('first')" placeholder="First Name *" required>
                        <span class="form-error">Please enter your First Name</span>
                        <span class="error" v-if="errorFirstName">A users first name may only include Letters, Numbers, space, period, comma or hyphen.</span>
                    </label>
                </div>
                <div class="cell large-6">
                    <label for="lastName">
                        <input type="text" v-model="dataUser.user.lastName" @blur="validateName('last')" placeholder="Last Name *" required>
                        <span class="form-error">Please enter your Last Name</span>
                        <span class="error" v-if="errorLastName">A users last name may only include Letters, Numbers, space, period, comma or hyphen.</span>
                    </label>
                </div>
                <div class="cell large-6">
                    <label for="gender">
                        <select v-model="dataUser.user.gender" required>
                            <option value="0">Female</option>
                            <option value="1">Male</option>
                            <option value="2">Other</option>
                            <option value="3">Prefer not to say</option>
                        </select>
                        <span class="form-error">Please enter your Gender</span>
                    </label>
                </div>
                <div class="cell large-6">
                    <label for="birthdate">
                        <input type="text" :value="customFormatter(dataUser.user.birthdate)" placeholder="Date Of Birth"
                            disabled>
                    </label>
                </div>
            </div>
            <!-- STEP 2 -->
            <div class="grid-x grid-margin-x align-justify block-section">
                <div class="cell large-12">
                    <h2>Mailing Address</h2>
                    <p class="legend">
                        <i>We’re looking forward to sending your rewards to you! Please provide a valid street address
                            for
                            your account registration. We cannot accept PO Boxes or addresses outside the US at this
                            time.
                            Thank you for your understanding.</i>
                    </p>
                </div>
                <div class="cell large-12">
                    <label>
                        <input type="text" v-model="dataUser.address.address" placeholder="Address Line 1 *" required>
                        <span class="form-error">Please enter your Address</span>
                    </label>
                </div>
                <div class="cell large-12">
                    <label>
                        <input type="text" v-model="dataUser.address.address2" placeholder="Address Line 2">
                    </label>
                </div>
                <div class="cell large-6">
                    <label>
                        <input type="text" placeholder="City *" v-model="dataUser.address.city" required>
                        <span class="form-error">Please enter your City</span>
                    </label>
                </div>
                <div class="cell large-3">
                    <label>
                        <select id="stateSelect" v-model="dataUser.address.state" required>
                            <option value="AL"> AL</option>
                            <option value="AK"> AK</option>
                            <option value="AZ"> AZ</option>
                            <option value="AR"> AR</option>
                            <option value="CA"> CA</option>
                            <option value="CO"> CO</option>
                            <option value="CT"> CT</option>
                            <option value="DC"> DC</option>
                            <option value="DE"> DE</option>
                            <option value="FL"> FL</option>
                            <option value="GA"> GA</option>
                            <option value="HI"> HI</option>
                            <option value="ID"> ID</option>
                            <option value="IL"> IL</option>
                            <option value="IN"> IN</option>
                            <option value="IA"> IA</option>
                            <option value="KS"> KS</option>
                            <option value="KY"> KY</option>
                            <option value="LA"> LA</option>
                            <option value="ME"> ME</option>
                            <option value="MD"> MD</option>
                            <option value="MA"> MA</option>
                            <option value="MI"> MI</option>
                            <option value="MN"> MN</option>
                            <option value="MS"> MS</option>
                            <option value="MO"> MO</option>
                            <option value="MT"> MT</option>
                            <option value="NE"> NE</option>
                            <option value="NV"> NV</option>
                            <option value="NH"> NH</option>
                            <option value="NJ"> NJ</option>
                            <option value="NM"> NM</option>
                            <option value="NY"> NY</option>
                            <option value="NC"> NC</option>
                            <option value="ND"> ND</option>
                            <option value="OH"> OH</option>
                            <option value="OK"> OK</option>
                            <option value="OR"> OR</option>
                            <option value="PA"> PA</option>
                            <option value="DC"> PR</option>
                            <option value="RI"> RI</option>
                            <option value="SC"> SC</option>
                            <option value="SD"> SD</option>
                            <option value="TN"> TN</option>
                            <option value="TX"> TX</option>
                            <option value="UT"> UT</option>
                            <option value="VA"> VA</option>
                            <option value="VI"> VI</option>
                            <option value="VT"> VT</option>
                            <option value="WA"> WA</option>
                            <option value="WV"> WV</option>
                            <option value="WI"> WI</option>
                            <option value="WY"> WY</option>
                        </select>
                        <span class="form-error">Please enter your State</span>
                    </label>
                </div>
                <div class="cell  large-3">
                    <label>
                        <input type="text" minlength="5" maxlength="5" placeholder="Zip Code *"
                            v-model="dataUser.address.zipCode" required pattern="number">
                        <span class="form-error">Please provide a valid Zip Code</span>
                    </label>
                </div>
                <div class="cell medium-6">
                    <label>
                        <input type="tel" id="phoneInput" v-model="dataUser.user.phoneNumber"
                            placeholder="Phone Number *" required pattern="^\d{3}-\d{3}-\d{4}$"
                            @change="formatPhone(dataUser.user.phoneNumber)">
                        <span class="form-error">Please provide a valid Phone Number</span>
                    </label>
                </div>
            </div>
            <!-- STEP 3 -->
            <div class="grid-x grid-margin-x align-justify block-section">
                <div class="cell large-12">
                    <h2>Login Information</h2>
                </div>
                <div class="cell large-12">
                    <label>
                        <input type="text" id="email-address" :value="dataUser.email" placeholder="Email Address *"
                            disabled>
                    </label>
                </div>
                <!-- <div class="cell large-12">
                    <label>
                        <input type="text" placeholder="Confirm Email Address *" v-model="dataUser.email" pattern="email"
                            data-equalto="email-address" required>
                        <span class="form-error">Emails do not match</span>
                    </label>
                </div> -->
            </div>
            <!-- SUBMIT FORM / CANCEL -->
            <div class="grid-x grid-margin-x block-section">
                <div class="cell large-12 action-section">
                    <button class="button submit-button" @click="submitForm" :disabled="errorFirstName || errorLastName">Update Profile</button>
                    <a href="#" @click="resetForm">Cancel Changes</a>
                </div>
                <hollow-dots-spinner :animation-duration="1000" :dot-size="12" :dots-num="3" color="#0FD8C9"
                    v-if="isLoading" />
            </div>
        </form>
        <div class="deleteAccount" v-if="isUserLogged">
            <p>I would like to permanently <a href="/my-profile/delete-your-account">delete my account</a>.</p>
        </div>

        <!-- MODAL -->
        <div :class="{ 'reveal callout terms-modal': true, 'error-modal': modal.modalError }" id="modalAccountProfile"
            data-reveal data-close-on-click="false">
            <div class="grid-x">
                <h5>{{ modal.title }}</h5>
                <div class="cell small-12">
                    <div v-html="modal.description"></div>
                </div>
            </div>
            <button type="button" class="close-button" data-close aria-label="Close modal" v-if="modal.btnClose">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import moment from "moment";
import UserLoginVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';
import { HollowDotsSpinner } from 'epic-spinners'

export default {
    components: {
        UserLoginVue,
        HollowDotsSpinner
    },
    data: function () {
        return {
            token: '',
            isUserLogged: false,
            isLoading: false,

            dataUser: {
                userId: '',
                email: '',
                address: {
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    zipCode: ''
                },
                user: {
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    birthdate: '',
                    nickname: '',
                    altPhone: '',
                    termsAcceptance: true,
                    phoneMFVerified: true,
                    pendingMFPhoneVerification: true,
                    isUserEnrolledInCashless: true,
                    tempMFPhoneCode: '',
                    tempMFPhoneNumber: '',
                    tempMFPhoneSMSEnabled: true,
                    tempMFAltPhoneNumber: '',
                    tempMFAltPhoneSMSEnabled: true,
                    primaryPhoneForTxt: true,
                    altPhoneForTxt: true,
                    gender: ''
                },
                validateAddress: true
            },
            dataUserReset: {},
            customErrors: {
                birth_error: false
            },
            modal: {
                title: '',
                description: '',
                modalError: false,
                btnClose: false
            },
            errorFirstName: false,
            errorLastName: false,
        }
    },
    mounted: function () {
        $(document).foundation();
        this.getToken();
    },
    methods: {
        getToken() {
            this.isUserLogged = this.isUserAuthed();
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    if (this.isUserLogged) {
                        this.getUser();
                    }
                });
        },
        getUser() {
            let USER_ID = JSON.parse( sessionStorage.getItem('apiToken'));
            let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUser/' + USER_ID.user.userId, apiToken = this.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + apiToken
                }
            }).then((response) => response.json()).then((json) => {
                let data = json.data;
                this.dataUser = {
                    userId: data.userId,
                    email: data.email,
                    address: {
                        address: data.address.address,
                        address2: data.address.address2,
                        city: data.address.city,
                        state: data.address.state,
                        zipCode: data.address.zipCode
                    },
                    user: {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        phoneNumber: data.userDto.phoneNumber,
                        birthdate: data.userDto.birthdate,
                        nickname: data.nickname,
                        altPhone: data.userDto.altPhone,
                        termsAcceptance: data.userDto.termsAcceptance,
                        phoneMFVerified: data.userDto.phoneMFVerified,
                        pendingMFPhoneVerification: data.userDto.pendingMFPhoneVerification,
                        isUserEnrolledInCashless: data.userDto.isUserEnrolledInCashless,
                        tempMFPhoneCode: data.userDto.tempMFPhoneCode,
                        tempMFPhoneNumber: data.userDto.tempMFPhoneNumber,
                        tempMFPhoneSMSEnabled: data.userDto.tempMFPhoneSMSEnabled,
                        tempMFAltPhoneNumber: data.userDto.tempMFAltPhoneNumber,
                        tempMFAltPhoneSMSEnabled: data.userDto.tempMFAltPhoneSMSEnabled,
                        primaryPhoneForTxt: data.userDto.primaryPhoneForTxt,
                        altPhoneForTxt: data.userDto.altPhoneForTxt,
                        gender: data.userDto.gender
                    },
                    validateAddress: true
                };

                this.dataUserReset = { ...this.dataUser };
                this.formatPhone(data.userDto.phoneNumber);
            });
        },
        validateName(type) {
            let name;

            if (type === 'first') {
                name = this.dataUser.user.firstName;
            } else {
                name = this.dataUser.user.lastName;
            }

            let valid = false;

            let regex = /^[A-Za-z0-9,-.\s]+$/;

            let tested = regex.test(name);

            tested === true ? valid = true : valid = false;

            if (valid === false) {
                type === 'first' ? this.errorFirstName = true : this.errorLastName = true;
            } else {
                this.errorFirstName = false;
                this.errorLastName = false;
            }
        },
        updateProfile() {
            this.isLoading = true;

            //FORM
            var apiToken = this.token
            let body = this.dataUser;

            const methodHeaderData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken
                },
                body: JSON.stringify(body)
            };

            if (_this.errorFirstName === false && _this.errorLastName === false) {
                fetch("https:" + process.env.VUE_APP_APIURL + "/1.0/PAM/UpdateProfile/", methodHeaderData)
                    .then(response => response.json())
                    .then(dataRegister => {
                        if (dataRegister.data == null) {
                            this.isLoading = false;
                            //MODAL ERROR
                            this.modal = {
                                title: "ERROR: Updating your Profile",
                                description: "<p>" + dataRegister.error.message + "</p>",
                                modalError: true,
                                btnClose: true
                            }
                            this.openModal('modalAccountProfile');

                        } else {
                            this.isLoading = false;
                            //MODAL SUCCESS
                            this.modal = {
                                title: "Updating your Profile",
                                description: "<p>Your changes have been saved.</p>",
                                btnClose: true
                            }
                            this.openModal('modalAccountProfile');

                            //UPDATE COOKIE
                            this.setTokenInfo();
                        }
                    });
            }
        },
        customFormatter(date) {
            return moment(date).format('MM/DD/yyyy');
        },
        formatPhone(val) {
            const numericValue = val.replace(/\D/g, '');
            if (numericValue.length === 10) {
                this.dataUser.user.phoneNumber = `${numericValue.slice(0, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6)}`;
            } else {
                this.dataUser.user.phoneNumber = numericValue;
            }
        },
        dateSelected() {
            let _this = this;
            this.$nextTick(function () {
                function getAge(birth, target) {
                    let months = target.diff(birth, 'months', true)
                    let birthSpan = { year: Math.floor(months / 12), month: Math.floor(months) % 12, day: Math.round((months % 1) * target.daysInMonth(), 0) }
                    if (birthSpan.year < 1 && birthSpan.month < 1) {
                        return 10
                    } else if (birthSpan.year < 1) {
                        return 10
                    } else if (birthSpan.year < 2) {
                        return 10
                    } else {
                        return birthSpan.year
                    }
                }
                let birth = moment(this.dataUser.user.birthdate)
                let ageOfPerson = getAge(birth, moment());
                if (ageOfPerson < 18) {
                    _this.customErrors.birth_error = true
                } else {
                    _this.customErrors.birth_error = false;
                }
            });

        },
        openModal(modalID) {
            $('#' + modalID).foundation('open');
        },
        closeModal(modalID) {
            $('#' + modalID).foundation('close');
        },
        submitForm(e) {
            let _this = this;
            if (_this.errorFirstName === false && _this.errorLastName === false) {
                $(document)
                    .on("forminvalid.zf.abide", function (ev, frm) {
                        console.log("FORM ERROR");
                    })
                    .on("formvalid.zf.abide", function (ev, frm) {
                        _this.updateProfile();
                        //MODAL
                        _this.modal = {
                            title: "Updating your Profile",
                            description: "<p>Please wait a moment...</p>",
                        }
                        _this.openModal('modalAccountProfile');
                    })
                    .on("submit", function (ev) {
                        ev.preventDefault();
                    });
            }
        },
        resetForm(e) {
            e.preventDefault();
            location.reload();
        },
        setTokenInfo() {
            let tokenInfo = { ...JSON.parse(sessionStorage.getItem('TokenInfo') ) };
            if (tokenInfo) {
                tokenInfo.User.FirstName = this.dataUser.user.firstName;
                tokenInfo.User.LastName = this.dataUser.user.lastName;
                tokenInfo.User.Gender =  this.dataUser.user.gender;
                tokenInfo.User.Address.Address1 = this.dataUser.address.address;
                tokenInfo.User.Address.Address2 = this.dataUser.address.address2;
                tokenInfo.User.Address.City = this.dataUser.address.city;
                tokenInfo.User.Address.State = this.dataUser.address.state;
                tokenInfo.User.Address.Zip = this.dataUser.address.zipCode;
                tokenInfo.User.Birthdate = this.dataUser.phoneNumber;

                //this.$cookies.set('TokenInfo', tokenInfo);
                sessionStorage.setItem('TokenInfo', JSON.stringify(tokenInfo));
            }
        },
    }
};
</script>