<template>
  <div class="search-bar-container">
    <form @submit.prevent="onSearch" class="search-form">
      <button 
        type="button"
        id="catalog-search-btn" 
        class="fa fa-search icon-magnifier" 
        style="cursor: pointer;"
        @click="onSearch"
        >
      </button>
      <input
        type="text"
        v-model="query"
        class="search-input"
        :placeholder="placeholder"
      />
      <button v-if="query"
        type="button"
        id="catalog-search-clear-btn" 
        class="icon-modal-close"
        style="cursor: pointer;"
        @click="clearInput"
        >
      </button>
    </form>
    <button 
        v-if="showCancel"
        type="button"
        class="cancel-btn"
        style="cursor: pointer;"
        @click="cancelSearch"
      >Cancel
    </button>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      query: this.modelValue || "",
      placeholder: "Search Catalog",
      previousUrl: "/",
    };
  },
  computed: {
    showCancel() {
      return window.location.pathname.includes("/catalog-search");
    },
  },
  watch: {
    modelValue(newValue) {
      this.query = newValue;
    },
    query(newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
  mounted(){
    const urlParams = new URLSearchParams(window.location.search);
    const queryParam = urlParams.get("query");
    if (queryParam && !this.modelValue) {
      this.query = queryParam;
    }

    //remember URL
    const referrer = document.referrer;
    const storedPreviousUrl = localStorage.getItem("previousUrl");

    if (window.location.pathname.includes("/catalog-search")) {

      if (window.location.pathname.includes("/catalog-search")) {
        if (referrer && !referrer.includes("/catalog-search")) {
          this.previousUrl = referrer;
          localStorage.setItem("previousUrl", referrer);
        } else if (storedPreviousUrl) {
          this.previousUrl = storedPreviousUrl;
        }
      } else {
        localStorage.setItem("previousUrl", window.location.href);
      }
    }
    
  },
  methods: {
    onSearch() {
      if (this.query.trim()) {
        window.location.href = `/rewards-catalog/catalog-search?query=${encodeURIComponent(this.query)}`;
      }
    },
    clearInput() {
      this.query = "";
    },
    cancelSearch() {
      window.location.href = this.previousUrl;
    },
  },
};
</script>

<style scoped>
#catalog-search-btn{
  box-shadow: none;
  padding: 0px 5px;
  color: #5c5c5c;
}

#catalog-search-clear-btn{
  box-shadow: none;
  background-color: #5c5c5c;
  color: white;
  padding:0px;
  min-width: 25px;
  min-height: 25px;
  scale: .6;
}

.search-form {
  display: flex;
  align-items: center;

  width: 98.5%;
  height: 38px;
  padding: 0px 5px;

  background-color: white;
  border: 1px solid #979797;
  border-radius: 10px;

}

.search-input {
  width: 100%;
  height: 38px;

  border: none;
  background-color: transparent;

  font-size: 14px;
}

.search-bar-container{
  display:flex;
  text-align: center;
  margin-bottom: 20px;

}

.cancel-btn {
  background: none;
  box-shadow: none;
  border-radius: 0;
  border: none;

  color: #417718;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  height: 40px;
  min-height: 38px;
  
  padding:0px;
  margin-left: 10px;
  text-transform: none;
}

</style>
