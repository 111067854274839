<template>
  <div class="ContentWrapper">
    <section class="categoryPageHeader">
        <h1>
            {{ headline }}
        </h1>
    </section>
    
    <div class="module">
      <CatalogSearchBar v-model:query="query"></CatalogSearchBar>
      
      <section v-if="currentItems.length" class="catalogList">
        <ul class="productList">
          <li v-for="product in currentItems" :key="product.skuNumber" class="product">
            <span class="productPrice">
                {{product.price.toLocaleString()}} Points
            </span>
            <div class="productItem cf callout light boxShadow">
              <a :href="product.nodeAliasPath" class="productImg">
                <img :src="product.image" alt="product.description">
              </a>
            </div>
            <article class="productInfo">
              <a :href="product.nodeAliasPath" class="productTitle">
                {{product.name}}
              </a>
            </article>
          </li>
        </ul>
      </section>
      <section v-if="noItems">
        <p class="noItems">Sorry, there are no catalog results for your search term.</p>
      </section>

      <Pagination :totalPages="totalPages" :itemsPerPage="itemsPerPage" :currentPage="currentPage" @changePage="changePage" :center="true" :right="true"></Pagination>
    </div>


  </div>
</template>


<script>
import Pagination from '../../Pagination/pagination'
import CatalogSearchBar from './CatalogSearchBar.vue';


    export default {
        components: {
            Pagination,
            CatalogSearchBar,
        },
        data: function() {
            return {
                query: "",
                token: '',
                products: [],
                noItems: false,
                itemCount: 0,
                items: 0,
                totalPages: 0,
                itemsPerPage: this.items / this.totalPages,
                currentPage: 1,
                currentItems: [],
                headline: "CATALOG SEARCH",
            }
        },
        mounted: function() {
            this.initializeQuery();
            this.getToken();
        },
        methods: {
          initializeQuery() {
            const urlParams = new URLSearchParams(window.location.search);
            const queryParam = urlParams.get("query");
            if (queryParam) {
              this.query = decodeURIComponent(queryParam);
            }
          },
            getToken() {
                this.getAPItoken()
                .then((token) => {
                  this.getProducts(token);
                });
            },
            getProducts(token) {
              const urlParams = new URLSearchParams(window.location.search);
              const queryParam = urlParams.get("query");
              
                let apiToken = token,
                    url = process.env.VUE_APP_APIURL+'/1.0/Ecommerce/GetProductList?search=' + queryParam;

                return fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                  console.log(json.data);

                  if(json.data.length > 0){
                    this.noItems = false;
                    this.setStock(json.data);
                  }else{
                    this.noItems = true;
                  }              
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },

            setStock(json) {
                let data = json,
                    products = [];

                for (let i = 0; i < data.length; i++) {
                    data[i].stock > 0 ? products.push(data[i]) : false;
                }

                this.itemCount = data.length;

                this.items = products;
                this.products = products.sort((x,y) => {
                        return x.price - y.price;
                    });

                this.setPages();
                this.setItems();
            },
            
            setPages() {              
                this.itemsPerPage = 24;
                this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
            },
            changePage(p) {
                console.log(p)
                this.currentPage = p;
                this.setItems();
            },
            setItems() {                 
                let start = (this.itemsPerPage * this.currentPage) - this.itemsPerPage,
                    end = (this.itemsPerPage * this.currentPage),
                    currentItems = this.items.slice(start, end);

                this.currentItems = currentItems;
            }
        }
    }
</script>

<style>
.CatalogSearchWrapper .module.module_pageContent{
 padding:0px;
}

.ContentWrapper{
  background-color: #f5f4f4;
}

.noItems{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

</style>