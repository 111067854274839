<template>
    <section class="productCategoryListSection">
        <CatalogSearchBar></CatalogSearchBar>
        <h2>Categories</h2>
        <div class="productCategoryListGrid">
            <a :href="'/rewards-catalog/'+ category.codeName" class="productCategoryListItem" v-for="(category, index) in categories" :key="index">
                <div class="productCategoryListItemImg">
                    <img :src="'/site_images/product_categories/'+toLowercase(category.codeName)+'.png'" :alt="category.name" />
                </div>
                <div class="productCategoryListItemDesc">
                    {{ category.name }}
                </div>
            </a>
        </div>
    </section>
</template>

<script>
import CatalogSearchBar from './Catalog/CatalogSearchBar.vue';

export default {
    components: {
        CatalogSearchBar,
    },
    data: function () {
        return {
            categories: []
        }
    },
    mounted: function () {
        this.getToken()
    },
    methods: {
        getToken() {
            this.getAPItoken().then((token) => {
                let sessionCategories = sessionStorage.getItem('categoryList');

                if (sessionCategories) {
                    this.categories = JSON.parse(sessionCategories);
                } else {
                    this.fetchCategoryList(token);
                }

                this.token = token;
            });
        },
        fetchCategoryList(token) {
            let apiToken = token,
                url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetCategoryList';

            return fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken,
                    },
                }
            )
            .then((response) => response.json())
            .then((json) => {
                let data = json.data;
                this.categories = data;
                // Hit mixin for category images -- then (mixin yet to be created)
                // Add categories to session storage
                sessionStorage.setItem('categoryList', JSON.stringify(data));
            })
            .catch((error) => {
                // console.error("There was an error!", error);
                return (this.errorMessage = error);
            });
        },
        toLowercase(string) {
            let lowercasestring = string.toLowerCase();
            return lowercasestring;
        }
    },
    computed: {

    },
};
</script>

<style lang="scss" scoped>
    h2 {
        margin-top: 42px;
    }
</style>