// This file is where we will gather all Vue "loader" modules, 
// each controlling the initialization of an app, 
// and call their load() function (aliased to avoid name conflicts) 
// within the loadComponents() function.

// list loader modules
// Home 
import { load as loadStatic } from "./Shared/static-loader";
import { load as loadTokenTimeout } from "./Shared/token-timeout-loader";
import { load as loadHome } from "./Home/home-loader";
import { load as loadPromotionBanner } from "./../ViewComponents/PromotionBanner/promotion-banner-loader";

// Menu
import { load as loadContentChildrenMenu } from "./Menu/content-children-menu-loader";
import { load as loadSecondaryMenu } from "./Menu/secondary-menu-loader";
import { load as loadLeftNavMenu } from "./Menu/left-nav-menu-loader";
import { load as loadLeftNavMenuStatic } from "./Menu/left-nav-menu-static-loader";
import { load as loadMobileMenu } from "./Menu/mobile-menu-loader";
import { load as loadSiteMenu } from "./Menu/site-menu-loader";
import { load as loadFooter } from "./Shared/footer-loader";
// import { load as loadWinningNumbersMenu } from "./Menu/winningNumbers-menu-loader";
// import { load as loadWinningNumbersMenuMobile } from "./Menu/winningNumbers-menu-mobile-loader";

// Shared
import { load as loadUserMessage } from "./Shared/user-message-loader";
import { load as loadUserLogin } from "./Shared/user-login-loader";
import { load as loadTerms } from "./Account/terms-and-conditions-loader";
import { load as loadWinner } from "./Account/instant-winner-loader";

// Games
// import { load as loadPowerBallWN } from "./Games/PowerBall/PowerBall-winningnumbers-loader";
// import { load as loadMegaWN } from "./Games/MegaMillions/MegaMillions-winningnumbers-loader";
// import { load as loadEasyPlay } from "./Games/EasyPlay/EasyPlay-landing-loader";
// import { load as loadEasyPlayList } from "./Games/EasyPlay/EasyPlay-List-loader";
// import { load as loadScratchOffsList } from "./Games/ScratchOffs/scratchoffs-list-loader";
// import { load as loadScratchOffsGame } from "./Games/ScratchOffs/scratchoffs-game-loader";
// import { load as loadScratchOffsSingleGame } from "./Games/ScratchOffs/scratchoffs-single-game-loader";
// import { load as loadScratchOffsPrice } from "./Games/ScratchOffs/scratchoffs-price-loader";
// import { load as loadScratchOffsLastDay } from "./Games/ScratchOffs/scratchoffs-last-day-loader";
// import { load as LoadDrawDetail } from "./Games/Draw-Details/draw-detail-loader";
// import { load as loadScratchOffsCarousel } from "./Games/PromoCarousel/scratchoff-carousel-loader";
// import { load as LoadWnAnnualExport} from "./WinningNumbers/NumbersAnnualArchive/winning-annual-export-loading";
// import { load as LoadScratchOffsPrizesRemaining } from "./Games/ScratchOffs/scratchOffs-prizes-remaining-loader";

// Draw Numbers
// import { load as LoadDrawingLanding } from "./DrawNumbers/drawing-landing-loader";
// import { load as LoadDrawingWinners } from "./DrawNumbers/drawing-winners-loader";

// Winning Numbers
// import { load as LoadWinningLanding } from "./WinningNumbers/winning-landing-loader";
// import { load as loadWNArchivePicker } from "./WinningNumbers/winning-numbers-archive-picker-loader";
// import { load as loadWNLandingArchive  } from "./WinningNumbers/winning-landing-archive-loader";
// import { load as loadDrawing  } from "./WinningNumbers/drawing-loader";
// import { load as loadRandomizer } from "./WinningNumbers/randomizer-loader";
// import { load as LoadCheckYourNumbers } from "./WinningNumbers/check-your-numbers-loader";
// import { load as LoadCheckYourNumbersTabs } from "./WinningNumbers/check-your-numbers-tabs-loader";

// Find retailer
import { load as LoadFindRetailer } from "./FindRetailer/find-retailer-loader";

// Survey
import { load as LoadSurveyWizard} from "./Survey/survey-wizard-loader";
// import { load as LoadClubMemberSurvey} from "./Survey/club-member-survey-loader";

// Rewards
import { load as LoadRewardsGreeting } from "./Rewards/rewards-greeting-loader";
import { load as LoadRewardsGreetingNoTicket } from "./Rewards/rewards-greeting-no-ticket-loader";
import { load as LoadRewardsGreetingDynamic } from "./Rewards/rewards-greeting-dynamic-loader";
import { load as LoadSecondChanceLanding} from "./Rewards/SecondChance/second-chance--landing-loader";
import { load as LoadSecondChanceDetail} from "./Rewards/SecondChance/second-chance--detail-loader";
import { load as LoadGamesForPrizesDetail} from "./Rewards/GamesForPrizes/games-for-prizes--detail-loader";
import { load as LoadGamesForPrizesList } from "./Rewards/GamesForPrizes/games-for-prizes--list-loader";
import { load as LoadCatalogList} from "./Rewards/Catalog/catalog-list-loader";
import { load as LoadCatalogSearch} from "./Rewards/Catalog/catalog-search-loader";
import { load as LoadWishlist} from "./Rewards/Wishlist/wish-list-loader";
import { load as LoadDigitalCatalogList} from "./Rewards/DigitalCatalog/digital-catalog-list-loader";
import { load as LoadDrawingsCatalogList} from "./Rewards/Drawings/drawingscatalog-list-loader";
import { load as LoadCouponsList} from "./Rewards/Coupons/coupons-list-loader";
import { load as LoadProductDetail} from "./Rewards/product-loader";
import { load as LoadProductDetailDrawings} from "./Rewards/product-detail-drawings-loader";
import { load as LoadProductDetailDigital} from "./Rewards/product-detail-digital-loader";
import { load as LoadEntriesList} from "./Rewards/DrawingEntries/drawing-entires-loader";
import { load as LoadRedemptionCentral} from "./Rewards/redemption-central-loader";
import { load as LoadStockAlert} from "./Rewards/stock-alert-loader";
import { load as LoadAdditionalRewards} from "./Rewards/additional-rewards-loader";
import { load as LoadAdditionalRewardsLanding} from "./Rewards/additional-rewards-landing-loader";
import { load as LoadAboutTheProgram} from "./Rewards/about-the-program-loader";
import { load as LoadProductCategoryList} from "./Rewards/product-category-list-loader.js";

// 8Bit
import { load as LoadEightBitatBat } from "./8Bit/atBat/eight-bit-at-bat-loader";

// Register
import { load as LoadRegister} from "./Register/register-loader";
import { load as loadSignInRegister} from "./../ViewComponents/SignInRegister/sign-in-register-loader";

// Edit Profile
import { load as LoadEditProfile } from "./EditProfile/edit-profile-loader";

// Account
import { load as LoadAccountProfile } from "./Account/account-profile-loader";
import { load as LoadChangePassword } from "./Account/change-password-loader";
import { load as LoadRetrievePassword } from "./Account/retrieve-password-loader";
import { load as LoadNotificationForm} from "./Account/notification-settings-form-loader";
import { load as LoadNotification} from "./Account/notification-settings-loader";
import { load as LoadDeleteAccount } from "./Account/delete-account-loader";
import { load as LoadConfirmDeleteAccount } from "./Account/confirm-delete-account-loader";
import { load as LoadTransactionLogTickets } from "./Account/transaction-log-tickets-loader";
import { load as LoadTransactionLogAwards } from "./Account/transaction-log-awards-loader";
import { load as LoadTransactionLogPurchases } from "./Account/transaction-log-purchases-loader";
import { load as LoadPhysicalPurchases } from "./Account/physical-purchases-loader";
import { load as LoadTransactionLog } from "./Account/transaction-log-loader";
import { load as LoadPhysicalPurchasesDetail } from "./Account/physical-purchases-detail-loader";
import { load as LoadCashCoupons } from "./Account/cash-coupons-loader";
import { load as LoadCashCouponsDetail } from "./Account/cash-coupons-detail-loader";
import { load as LoadDigitalPurchases } from "./Account/digital-purchases-loader";
import { load as LoadDigitalPurchasesDetail } from "./Account/digital-purchases-detail-loader";
import { load as LoadMobileCashing } from "./Account/mobile-cashing-loader";
import { load as LoadVerifiedPhone } from "./Account/verified-phone-loader";
import { load as LoadAccountHeader } from "./Account/myaccount-header-loader";
import { load as LoadForgotPassword } from "./Account/forgot-password-loader";

// Ticker Checker & Ticket Entry
import { load as LoadTicketChecker} from "./TicketChecker/ticket-checker-loader";
import { load as LoadTicketEntry} from "./TicketEntry/ticket-entry-loader";
import { load as LoadPromotionCodeEntry} from "./TicketEntry/promotion-code-entry-loader";

// View Components
import { load as LoadBannerCarousel } from "./../ViewComponents/BannerCarousel/banner-carousel-loader";
import { load as LoadBannerCarouselSlick } from "./../ViewComponents/BannerCarousel/banner-carousel-slick-loader";
// import { load as LoadCheckNumbersHome } from "./../ViewComponents/CheckNumbersHome/check-numbers-loader";
import { load as LoadSearchBox } from "./../ViewComponents/SearchBox/search-box-loader";

// Featured Winners
import { load as LoadFeaturedWinners} from "./FeaturedWinners/featured-winners-loader";

// Press Releases
import { load as LoadPressReleases} from "./PressReleases/press-releases-loader";

// Poll Question
import { load as LoadPollQuestion } from "./PollQuestion/poll-question-loader";

// Rules
import { load as LoadRulesAndRegulations} from "./Claiming/RulesAndRegulations/rulesandregulations-loader";

// Achievements
import { load as LoadAchievements} from "./Rewards/Achievements/achievements-loader";

// Purchases
import { load as LoadDigitalPurchasesList} from "./Account/digital-purchases-list-loader";
import { load as LoadPhysicalPurchasesList} from "./Account/physical-purchases-list-loader.js";


// list loader calls
export function loadComponents(store) {
    
    // Global //////////////////
    loadStatic(store);
    loadTokenTimeout();
    loadContentChildrenMenu(store);
    loadSecondaryMenu(store);
    loadLeftNavMenu(store);
    loadLeftNavMenuStatic();
    loadUserMessage(store);
    loadMobileMenu(store);
    loadSiteMenu(store); 
    loadFooter(store);
    LoadSearchBox();

    // Home
    loadHome(store);
    loadPromotionBanner();
    // LoadCheckNumbersHome();

    // Games Lists
    // loadScratchOffsList(store);
    // loadScratchOffsGame(store);
    // loadScratchOffsSingleGame(store);
    // loadScratchOffsPrice(store);
    // loadScratchOffsLastDay(store)
    // loadScratchOffsCarousel();
    // LoadScratchOffsPrizesRemaining();

    // Winning Numbers //////////////////
    // loadPowerBallWN();
    // loadMegaWN();
    // loadEasyPlay();
    // loadEasyPlayList(store);
    // LoadDrawDetail();
    // LoadDrawingLanding(store);
    // LoadDrawingWinners();
    // LoadWinningLanding(store);
    // loadWNArchivePicker();
    // loadWNLandingArchive();
    // loadDrawing();
    // loadRandomizer();
    // LoadCheckYourNumbers(store);
    // LoadCheckYourNumbersTabs(store);
    // LoadWnAnnualExport();

    // Account
    LoadAccountProfile();
    loadUserLogin(store);
    loadTerms();
    loadWinner();
    LoadRegister();
    LoadChangePassword();
    LoadRetrievePassword();
    loadSignInRegister();
    LoadDeleteAccount();
    LoadConfirmDeleteAccount();
    LoadTransactionLogTickets();
    LoadTransactionLogAwards();
    LoadTransactionLogPurchases();
    // LoadTransactionLog();
   
    LoadPhysicalPurchases();
    LoadPhysicalPurchasesDetail();
    LoadCashCoupons();
    LoadCashCouponsDetail();
    LoadDigitalPurchases();
    LoadDigitalPurchasesDetail();
    LoadMobileCashing();
    LoadVerifiedPhone();
    LoadAccountHeader();
    LoadForgotPassword();

    // Retailer
    LoadFindRetailer();

    // Rewards
    LoadSurveyWizard(store);
    LoadSecondChanceLanding();
    LoadSecondChanceDetail();
    LoadCouponsList();
    LoadPollQuestion();
    LoadRewardsGreeting();
    LoadRewardsGreetingNoTicket();
    LoadRewardsGreetingDynamic();
    LoadNotificationForm();
    LoadNotification();
    LoadTicketChecker();
    LoadTicketEntry();
    LoadPromotionCodeEntry();
    LoadEditProfile();
    LoadBannerCarousel();
    LoadBannerCarouselSlick();
    LoadGamesForPrizesDetail();
    LoadGamesForPrizesList();
    LoadFeaturedWinners();
    LoadProductCategoryList();

    LoadPressReleases();

    LoadProductDetail();
    LoadProductDetailDrawings();
    LoadProductDetailDigital();
    LoadEntriesList();
    LoadRedemptionCentral();
    LoadCatalogList();
    LoadCatalogSearch();
    LoadWishlist();
    LoadDigitalCatalogList();
    LoadDrawingsCatalogList();
    LoadStockAlert();
    LoadAdditionalRewards();
    LoadAdditionalRewardsLanding();
    LoadAboutTheProgram();
    
    // 8Bit
    LoadEightBitatBat();

    // Claiming / Rules
    LoadRulesAndRegulations();

    // Promotion Code
    LoadPromotionCodeEntry();

    // Achievements
    LoadAchievements();

    // Purchases
    LoadDigitalPurchasesList();
    LoadPhysicalPurchasesList();
}
